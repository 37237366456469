/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useShiftStore, { getShiftDetails } from '../../../../app/stores/company/shiftStore'
import useGeneralStore from '../../../../app/stores/others/generalStore'
import ShiftDetailsHeader from './ShiftDetailsHeader'
import ShiftDetailsMainContents from './ShiftDetailsMainContents'
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore'
import { k_shift_section } from '../../../../app/utility/const'
import { k_create_request_paths } from '../../../../app/utility/AllRoute'
import useRequestStore, { getRequestDetails } from '../../../../app/stores/others/requestStore'

const ShiftDetails = ({ withTopBar = true, isFullWidth = false, outlinedBox = false, shiftID = null }) => {
    const { setPathRecord } = useGeneralStore();
    const { shiftDetailsData } = useShiftStore();
    const { shiftExpandedAccordion, setShiftExpandedAccordion, savedCrFormRes, request_details } = useCreateRequestStore();
    const { redirectingToShiftDetails, setFetchReqDetails } = useRequestStore()

    const location = useLocation();
    const navigateTo = useNavigate();

    const { shift_id } = useParams();

    const fetchShiftDetails = () => {
        if(shift_id) {
            getShiftDetails(shift_id)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchShiftDetails()
        setShiftExpandedAccordion(k_shift_section.shift_overview);
    }, [shift_id]);

    useEffect(() => {
        if (location.pathname.includes(k_create_request_paths.shift_details) && !location?.state?.redirectFromRequest) {
            console.log("savedCrFormRes::: ", savedCrFormRes);
            if (location?.state?.is_fetch_shift_details === false) return;
            if (savedCrFormRes?.id && savedCrFormRes?.id !== undefined) {
                getRequestDetails("saved", savedCrFormRes?.id, false);
                console.log(" getRequestDetails from shift details::: request_details::: ", request_details);

            }
            setPathRecord(location.pathname);
        }
    }, [location.pathname]);

    useEffect(() => {
        if(redirectingToShiftDetails === null && !localStorage?.getItem("isShiftPath")) {
            let navigationUrl = localStorage?.getItem("shiftDetailsGoBack");
            setFetchReqDetails(true)
            navigateTo(navigationUrl, {state: {...location?.state, is_fetch_req_details: true}})
        }
    }, [savedCrFormRes, request_details])


    return (
        <div>

            {withTopBar && <ShiftDetailsHeader />}

            <div className='flex items-start h-full space-x-10' >

                <ShiftDetailsMainContents outlinedBox={outlinedBox} />

                {/*e  right sections.. */}
                {/* {
                    type ? <RequestDetailsSummary /> : <div className='min-w-[380px] max-w-[380px]'></div>
                } */}

                {!isFullWidth && <div className='min-w-[380px] max-w-[380px]'></div>}
            </div>
        </div>

    )
}

export default ShiftDetails