/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCreateRequestStore, { countAllStopsProducts } from '../../../../../app/stores/others/createRequestStore';
import useGeneralStore from '../../../../../app/stores/others/generalStore';
import useRequestStore from '../../../../../app/stores/others/requestStore';
import { create_request_steps, user_role as role } from '../../../../../app/utility/const';
import { formatSchedule, isEndTimeSmaller } from '../../../../../app/utility/utilityFunctions';
import Summary from '../../../../../components/utility/summary/Summary';

export default function CreateRequestSummary() {

  const { cr_form, current_step, crFormSelectedShift } = useCreateRequestStore();
  const { available_shifts } = useRequestStore();
  const { user_role } = useGeneralStore.getState();
  const location = useLocation();

  const { t } = useTranslation();

  const stop_valid_count = cr_form?.deliveries?.reduce((acc, stop) => {
    const { address, lat, lng } = stop;

    if (address && lat && lng) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const selectedShift = available_shifts?.find((i) => parseInt(i.id) === parseInt(crFormSelectedShift))

  const content2 = user_role === role.company ?
    [
      { title: t('Driver Name'), description: selectedShift?.driver_name ?? "", titleClassName: "summaryBorder" },
      { title: t('Vehicle Number'), description: selectedShift?.car_name ?? "" },
      { title: t('Customer Name'), description: cr_form?.proposal?.customer_name, titleClassName: "summaryBorder" },
      { title: t('Agreed Budget'), description: cr_form?.proposal?.budget ? `DKK ${parseInt(cr_form?.proposal?.budget)?.toLocaleString("da-DK")}` : '' },
    ] :
    [
      { title: t('Direct Invite'), description: current_step !== create_request_steps?.select_company || window.location?.pathname.includes("/request/edit") || (cr_form?.user?.id) ? (cr_form?.company_user_ids?.length?.toString()) : "", titleClassName: "summaryBorder" },
      { title: t('Global Invite'), description: current_step !== create_request_steps?.select_company || window.location?.pathname.includes("/request/edit") || (cr_form?.user?.id) ? (cr_form?.is_global ? t('Submitted') : "") : "" },
    ];


  const content = [
    { title: 'Status', description: location?.state?.referrer === "saved" ? t("Saved") : location?.state?.referrer === "awarded" ? t("Upcoming") : location?.state?.referrer === "in-bidding" ? t("In Bidding") : t("Create Request") },
    { title: 'Title', description: cr_form?.title?.length > 0 ? cr_form?.title : '' },
    { title: 'Type', description: cr_form.transport_type?.length > 0 ? cr_form?.transport_type : '' },
    {
      title: 'Pick Up Date & Time',
      description: cr_form?.pickup?.date ? formatSchedule(cr_form?.pickup?.date, cr_form?.pickup?.start_time, cr_form?.pickup?.end_time, " ", (isEndTimeSmaller(cr_form?.pickup?.start_time, cr_form?.pickup?.end_time) || cr_form?.pickup?.start_time === cr_form?.pickup?.end_time)) : formatSchedule(cr_form?.pickup?.start_time, cr_form?.pickup?.end_time, " ")
    },
    {
      title: 'Delivery Overview', description: (cr_form?.deliveries?.length > 0) ?
        `${stop_valid_count} ${cr_form?.deliveries?.length > 1 ? t('Stops') : t('Stop')} (${countAllStopsProducts(cr_form?.deliveries)} ${countAllStopsProducts(cr_form?.deliveries) > 1 ? t('Packages') : t('Package')})` : "", titleClassName: "summaryBorder"
    },
    ...content2
  ]

  return (
    <div onClick={() => {
      console.log("cr_form", cr_form);
    }} >
      <Summary width="min-w-[355px] max-w-[355px]" content={content} />
    </div >
  )
}
