/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useShiftStore from "../../../../../../app/stores/company/shiftStore";
import useCreateRequestStore, { isCrFormValidation, isFormComplete, saveRequest } from "../../../../../../app/stores/others/createRequestStore";
import useRequestStore, { getRequestShiftPlannerList } from "../../../../../../app/stores/others/requestStore";
import { all_shift_path, create_request_steps, k_cr_actions, k_request_paths, request_details_types, user_role as role } from "../../../../../../app/utility/const";
import RequestAccordion from "../../../../../../components/Accordion/RequestAccordion";
import AvailableShifts from "./components/AvailableShifts";
import AvailableShiftsView from "../../../../requests/requestDetails/components/summary/components/AvailableShifts";
import CommonEmptyView from "./components/CommonEmptyView";
import { k_create_request_paths } from "../../../../../../app/utility/AllRoute";
import ConfirmRequestDetailsUpdateModal from "../../../../requests/requestDetails/components/requestDetailsTopBar/components/modals/ConfirmRequestDetailsUpdateModal";
import { isDateInPast } from "../../../../../../app/utility/utilityFunctions";

export default function SelectShift() {
  const location = useLocation();

  const { request_id, type } = useParams();

  const isNotPlannedEditMode = location.pathname.includes(`requests/not-planned/details/${request_id}/edit`) || location.pathname.includes(`requests/awarded/details/${request_id}/edit`) || location?.pathname?.includes(`/request/edit/${request_id}`);
  const isNotPlannedViewMode = location.pathname.includes(`requests/not-planned/details/${request_id}`);

  const { available_shifts, setAvailableShifts, selectedShift, setSelectedShift, request_details, setRedirectingToShiftDetails } = useRequestStore();

  const { setShowAddShiftModal, setAllShiftCarList, setAllShiftDriverList, setShiftDetailsData, shiftDetailsData } = useShiftStore();

  const { isApplyGenerate, setAvailableShiftSearchKey, changeCrForm, cr_form, expandedAccordion, setExpandedAccordion, setCurrentSetup, crFormSelectedShift, setCrFormSelectedShift, newRequestShift, setNewRequestShift, showRequestUpdateConfirmModal, setShowRequestUpdateConfirmModal } = useCreateRequestStore();

  const [isExpandedShift, setExpandedShift] = useState(false);

  const { t } = useTranslation();

  const getNewRequestShift = (newShiftId = null) => {
    if (!newShiftId) return null;
    return available_shifts?.find((i) => parseInt(i.id) === parseInt(newShiftId))
  }

  const showShiftList = (isNotPlannedEditMode || isFormComplete(cr_form)) && !isDateInPast(cr_form?.pickup?.date);

  const isCreateRequestPath = location.pathname.includes("request/create")
  const isCustomerUpcomingEditPath = type === request_details_types.awarded && request_details?.user?.user_role !== role.company
  const hasSave = location.pathname.includes(`request/edit/${request_id}`) || location?.pathname === k_create_request_paths.create

  const navigateTo = useNavigate();
  
  const hasCrStops = () => {
    return cr_form?.pickup?.address && cr_form?.pickup?.lat && cr_form?.pickup?.lng && cr_form?.pickup?.date && cr_form?.pickup?.start_time && cr_form?.pickup?.end_time &&
      cr_form?.transport_type && cr_form?.deliveries?.length
  }

  const hasRequestStops = () => {
    return request_details?.pickup_address && request_details?.pickup_date && request_details?.pickup_start_time && request_details?.pickup_end_time &&
      request_details?.transport_type && request_details?.stops?.length;
  }

  const getShiftPlanText = () => {
    switch(true) {
      case location?.state?.referrer === request_details_types.invitation : return "No available shift found!"
      case location?.state?.referrer === request_details_types.in_bidding : return "No available shift found!"
      case type === request_details_types.invitation : return "No available shift found!"
      case type === request_details_types.in_bidding : return "No available shift found!"
      case location?.pathname?.includes("global-request"): return "No available shift found!"
      default: return "No shift plan found!"
    }
  }

  const selectedShiftInRequest = available_shifts?.find(item => item?.id == crFormSelectedShift)

  const getSubTitleOne = () => {
    if(location?.pathname?.includes("/request/create") || (location?.pathname?.includes("edit") && (
      location?.state?.referrer === request_details_types.saved || 
      (location?.state?.referrer === request_details_types.awarded && request_details?.user?.user_role === role.company) 
    ))) {
      if(isDateInPast(cr_form?.pickup?.date)) {
        return "No info added yet"
      }
      else if (hasCrStops()) {
        if(!crFormSelectedShift) {
          return available_shifts?.length ? available_shifts?.length > 1 ? available_shifts?.length + " Shifts found" : available_shifts?.length + " Shift found" : getShiftPlanText();
        }
        else return location?.pathname?.includes("edit") ? selectedShiftInRequest?.driver_name :  cr_form?.shift_plan?.driver_name
      }
      else return "No info added yet";
    }
    else if (!request_details?.shift_id && (location.pathname.includes(`requests/${type}/details/${request_id}`) || location?.pathname?.includes("global-request/details") || location?.pathname?.includes("edit"))) {
      if (hasRequestStops()) {
        if(crFormSelectedShift) {
          return selectedShiftInRequest?.driver_name;
        }
        else return available_shifts?.length ? available_shifts?.length > 1 ? available_shifts?.length + " Shifts found" : available_shifts?.length + " Shift found" : "No shift plan found!";
      }
      else return "No info added yet";
    }
    else if (type && request_details?.shift_with_car_and_driver?.driver_user?.name) {
      if(crFormSelectedShift == request_details?.shift_id) {
        return request_details?.shift_with_car_and_driver?.driver_user?.name;
      }
      else if(crFormSelectedShift) return selectedShiftInRequest?.driver_name;
      else return available_shifts?.length ? available_shifts?.length > 1 ? available_shifts?.length + " Shifts found" : available_shifts?.length + " Shift found" : getShiftPlanText();
    }
    else if (type === request_details_types.ongoing) {
      return request_details?.driver?.details?.name;
    }
    return "";
  }

  useEffect(() => {
    if (request_details?.id == request_id) {
      if (isNotPlannedEditMode) {
        isDateInPast(request_details?.pickup_stop?.date)
        if(isDateInPast(request_details?.pickup_stop?.date)) {
          return;
        }
        setAvailableShifts([]);
        setSelectedShift(null);
        const startTime = request_details?.pickup_stop?.start_time;
        const endTime = request_details?.pickup_stop?.end_time;
        const pickupDate = request_details?.pickup_stop?.date;
        getRequestShiftPlannerList(startTime, endTime, pickupDate, true);
      }
    }
  }, [request_details, isNotPlannedEditMode]);

  useEffect(() => {
    if (crFormSelectedShift) setNewRequestShift(getNewRequestShift(crFormSelectedShift));
    else if (cr_form?.shift_id) setNewRequestShift(getNewRequestShift(cr_form?.shift_id));
    else setNewRequestShift(null);
  }, [cr_form, crFormSelectedShift]);

  const handleSeeAllEvent = async() => {
    if (showShiftList && !isCustomerUpcomingEditPath) {
      setRedirectingToShiftDetails(true);
      localStorage?.setItem("goBack", location?.pathname)
      if(location?.state?.referrer === "awarded") {
        await saveRequest(cr_form?.shift_id ? k_cr_actions.generate : k_cr_actions?.save, false);
      }
      else await saveRequest(k_cr_actions.save, false, {}, location?.pathname !== k_create_request_paths.create);
    }

    setRedirectingToShiftDetails(true);
    setShowRequestUpdateConfirmModal(false);
    localStorage?.setItem("goBack", location?.pathname)
    setAvailableShiftSearchKey("");
    setCurrentSetup(create_request_steps?.allShifts)
    setShiftDetailsData(null)
    if (isCustomerUpcomingEditPath) {
      navigateTo(
        `/requests/awarded/details/${request_id}/edit/all-shifts`,
        {
          state:
          {
            ...location?.state,
            referrer: type,
            isEditMode: true,
            request_id: request_id,
            redirectFromRequest: true,
            isNotPlannedEditMode: isNotPlannedEditMode
          }
        }
      )
    }
    else {
      navigateTo(
        all_shift_path,
        {
          state:
          {
            ...location?.state,
            referrer: location?.state?.referrer ? location?.state?.referrer : location?.pathname?.includes(`/global-request/details/${request_id}`) ? "global-request" : type,
            isEditMode: (location.pathname?.includes(`/request/edit/${request_id}`) || location.pathname === `/request/create` || location.pathname === `/requests/${type}/details/${request_id}/edit`) ? true : false,
            request_id: request_id,
            redirectFromRequest: location.pathname.includes(`/requests/${type}/details/${request_id}`),
            isNotPlannedEditMode: isNotPlannedEditMode
          }
        }
      )
    }
  }

  return (
    <RequestAccordion
      isInvalid={isApplyGenerate && !isCrFormValidation()?.shift}
      onClick={() => setExpandedShift(!isExpandedShift)}
      showClearButton={false}
      isTable={available_shifts?.length > 0 ? true : false}
      title={expandedAccordion === create_request_steps.select_shift && showShiftList ? t("Shift Planner") + ` (${Object.values(available_shifts)?.length})` : t("Shift Planner")}
      id="accordion3"
      isExpanded={expandedAccordion === create_request_steps.select_shift}
      onExpand={() => {
        setExpandedAccordion(expandedAccordion === create_request_steps.select_shift ? null : create_request_steps.select_shift)
      }}
      secondaryTitle={showShiftList ? available_shifts?.length > 10 ? t("See all shifts") : t("Add Shift") : ""}
      handleSecondaryTitleClick={async() => {
        if(available_shifts?.length > 10) {
          if (showShiftList && !isCustomerUpcomingEditPath) {
            setRedirectingToShiftDetails(true);
            localStorage?.setItem("goBack", location?.pathname)
            if(location?.state?.referrer === "awarded") {
              await saveRequest(cr_form?.shift_id ? k_cr_actions.generate : k_cr_actions?.save, false);
            }
            else await saveRequest(k_cr_actions.save, false, {}, location?.pathname !== k_create_request_paths.create);
          }
      
          setRedirectingToShiftDetails(true);
          setShowRequestUpdateConfirmModal(false);
          localStorage?.setItem("goBack", location?.pathname)
          setAvailableShiftSearchKey("");
          setCurrentSetup(create_request_steps?.allShifts)
          setShiftDetailsData(null)
          if (isCustomerUpcomingEditPath) {
            navigateTo(
              `/requests/awarded/details/${request_id}/edit/all-shifts`,
              {
                state:
                {
                  ...location?.state,
                  referrer: type,
                  isEditMode: true,
                  request_id: request_id,
                  redirectFromRequest: true,
                  isNotPlannedEditMode: isNotPlannedEditMode
                }
              }
            )
          }
          else {
            navigateTo(
              all_shift_path,
              {
                state:
                {
                  ...location?.state,
                  referrer: location?.state?.referrer ? location?.state?.referrer : location?.pathname?.includes(`/global-request/details/${request_id}`) ? "global-request" : type,
                  isEditMode: (location.pathname?.includes(`/request/edit/${request_id}`) || location.pathname === `/request/create` || location.pathname === `/requests/${type}/details/${request_id}/edit`) ? true : false,
                  request_id: request_id,
                  redirectFromRequest: location.pathname.includes(`/requests/${type}/details/${request_id}`),
                  isNotPlannedEditMode: isNotPlannedEditMode
                }
              }
            )
          }
        } else {
          setShowAddShiftModal(true);
          setAllShiftCarList([]);
          setAllShiftDriverList([]);
        }
      }}
      subTitleOne={getSubTitleOne()}
    >
      <div>
        {
          // isNotPlannedViewMode ?
          //   <AvailableShiftsView withoutTitles />
          //   :
          showShiftList || isNotPlannedViewMode ?
            <AvailableShifts
              // selectedId={isNotPlannedEditMode ? selectedShift?.id : crFormSelectedShift}
              selectedId={crFormSelectedShift}
              onChange={async (item) => {
                if (isNotPlannedEditMode) {
                  if (item?.id === crFormSelectedShift) { 
                    setSelectedShift(null); 
                    setCrFormSelectedShift(null)
                    await changeCrForm("shift_plan", null);
                    await changeCrForm("shift_id", null);
                  }
                  else { 
                    setSelectedShift(item); 
                    setCrFormSelectedShift(item?.id);
                    await changeCrForm("shift_plan", item);
                    await changeCrForm("shift_id", item?.id);
                   }
                } else {
                  if (item?.id === crFormSelectedShift) {
                    await changeCrForm("shift_plan", null);
                    await changeCrForm("shift_id", null);
                    await setCrFormSelectedShift(null);
                  } else {
                    await changeCrForm("shift_plan", item);
                    await changeCrForm("shift_id", item?.id);
                    await setCrFormSelectedShift(item?.id);
                  }
                }
              }}
            />
            :
            <CommonEmptyView message={location.pathname.includes(k_request_paths.not_planned) ? "No shift available." : t("Fill out the required fields first.")} />

        }
        <ConfirmRequestDetailsUpdateModal showModal={showRequestUpdateConfirmModal} setShowModal={setShowRequestUpdateConfirmModal} onConfirm={handleSeeAllEvent} />

      </div>
    </RequestAccordion>
  );
}
