/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCarStore, { getAllLicenseList } from '../../../../app/stores/company/carStore';
import { Checkbox, FormControlLabel } from '@mui/material';
import LicenseCard from './LicenseCard';
import CommonDatePicker from '../../../../components/input/CommonDatePicker';
import CommonInput from '../../../../components/input/CommonInput';
import { getNextDate, getStringFromDateObject } from '../../../../app/utility/utilityFunctions';
import PurchaseLicenseInfo from './PurchaseLicenseInfo';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CarLicensePackageTopBar from './CarLicensePackageTopBar';
import { useNavigate } from 'react-router-dom';
import { iInformationGray } from '../../../../app/utility/imageImports';
import CommonCheckbox from '../../../../components/input/CommonCheckbox';
import CommonEmptyView from '../../../common/createRequest/components/content/selectShift/components/CommonEmptyView';
import LicenseCarousel from './LicenseCarousel';

const CarLicensePackage = ({ carDetails, handleAddCar = (() => { }) }) => {
    const {
        allCarLicenseList,
        licenseAddUpdateForm,
        setLicenseAddUpdateForm,
        carLicenseRenewID,
        newAddedCarID,
        isNoLicenseApply, setNoLicenseApply
    } = useCarStore();

    const [selectedLicensePack, setSelectedLicensePack] = useState(0);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const checkLicenseExists = (license_id) => {
        return allCarLicenseList.find(license => license.id === license_id);
    }

    const updateLicenseAddUpdateForm = async (reCallApi = true) => {
        if (reCallApi) await getAllLicenseList();
        await setLicenseAddUpdateForm({
            ...licenseAddUpdateForm,
            id: newAddedCarID ? newAddedCarID : carDetails?.id,
            license_id: checkLicenseExists(carLicenseRenewID) ? carLicenseRenewID : allCarLicenseList[0]?.id,
            license_start: '',
            purchase_license_comment: '',
        });
    }

    const handleLicenseCheckboxChange = async () => {
        await setNoLicenseApply(!isNoLicenseApply);
        if (!isNoLicenseApply) {
            setLicenseAddUpdateForm({ ...licenseAddUpdateForm, license_start: "", purchase_license_comment: "" });
            setTermsAccepted(false)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        updateLicenseAddUpdateForm();
        setTermsAccepted(false);
    }, [carLicenseRenewID, allCarLicenseList[0]?.id]);

    useEffect(() => {
        if (!carDetails?.name) navigateTo("/car-manager");
    }, []);

    useEffect(() => {
        updateLicenseAddUpdateForm();
        setTermsAccepted(false);
    }, [carLicenseRenewID, allCarLicenseList[0]?.id]);

    return (
        <div>
            <CarLicensePackageTopBar
                handleAddCar={handleAddCar}
                setTermsAccepted={setTermsAccepted}
                termsAccepted={termsAccepted}
            />

            <div className='flex space-x-10'>

                {allCarLicenseList?.length ?
                    <>
                        <div className='w-full'>
                            <div className='flex items-center justify-center'>
                                <div className='flex mb-5 space-x-5 select-none w-full'>
                                    <LicenseCarousel
                                        setSelectedLicensePack={setSelectedLicensePack}
                                        selectedLicensePack={selectedLicensePack}
                                        isNoLicenseApply={isNoLicenseApply}
                                    />
                                </div>
                            </div>



                            <div className='w-full'>
                                <div className='max-w-[600px]'>

                                    <PurchaseLicenseInfo disabled={isNoLicenseApply} data={allCarLicenseList[selectedLicensePack]} />

                                    {!carDetails?.id && <div className={`mt-5 cursor-pointer pl-[10px] ${isNoLicenseApply ? "bg-cPrimary50 border-[1px] border-cPrimary50" : "border-[1px] border-cGray200"} rounded-br4 w-fit`}>
                                        <ThemeProvider theme={createTheme({
                                            palette: {
                                                primary: {
                                                    main: '#285D43',
                                                },
                                            },
                                        })}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" defaultChecked={false} checked={isNoLicenseApply}
                                                    onChange={handleLicenseCheckboxChange} />}
                                                label={
                                                    <div className='checkBoxLabel'>
                                                        {t("Skip license for now")}
                                                    </div>}
                                            />
                                        </ThemeProvider>
                                    </div>}


                                    {
                                        <form onSubmit={(e) => e.preventDefault()} >
                                            <div className={`${isNoLicenseApply ? "text-cGray500" : "text-cGray800"} my-4 sub-title`}>{t("Apply for license")}</div>

                                            <div className='w-[60%]'>
                                                <CommonDatePicker
                                                    disabled={isNoLicenseApply}
                                                    required={!isNoLicenseApply}
                                                    show_asterisk={!isNoLicenseApply}
                                                    startDate={carDetails?.id && carDetails?.license_status === "expire_warning" ? getNextDate(carDetails?.license_end_raw) : null}
                                                    label={t('License Start Date')}
                                                    placeholder=''
                                                    autoFocus={false}
                                                    value={licenseAddUpdateForm?.license_start}
                                                    allowPastDate={false}
                                                    onChange={(e) => {
                                                        setLicenseAddUpdateForm({ ...licenseAddUpdateForm, license_start: getStringFromDateObject(e) });
                                                    }}
                                                />
                                            </div>

                                            <CommonInput
                                                // textAreaClassName="-bottom-[31px]"
                                                icon={iInformationGray}
                                                disabled={isNoLicenseApply}
                                                labelText={t('Application Note')}
                                                value={licenseAddUpdateForm?.purchase_license_comment}
                                                textarea={true}
                                                required={!isNoLicenseApply}
                                                show_asterisk={!isNoLicenseApply}
                                                rows={2}
                                                max_input={255}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.trim();
                                                    if (trimmedValue === "") {
                                                        setLicenseAddUpdateForm({
                                                            ...licenseAddUpdateForm,
                                                            purchase_license_comment: ""
                                                        });
                                                    } else {
                                                        setLicenseAddUpdateForm({
                                                            ...licenseAddUpdateForm,
                                                            purchase_license_comment: e.target.value
                                                        });
                                                    }
                                                }} />
                                            <div className='mt-[30px]'></div>

                                            <div className='flex space-x-2'>
                                                <CommonCheckbox
                                                    disabled={isNoLicenseApply}
                                                    checked={termsAccepted}
                                                    onChange={() => setTermsAccepted(!termsAccepted)} required={true}
                                                />

                                                <div className='flex items-center justify-center cursor-default'>
                                                    <div className={`mr-1 text-fs14 font-normal  ${isNoLicenseApply ? "text-cGray500" : "text-cGray800"}`}>{t("I agree with all")}  </div><a className={`text-fs14 font-normal ${isNoLicenseApply ? "text-cGray500 cursor-default pointer-events-none" : "text-cBrand cursor-pointer"}  font-fw500 underline`} href="https://limadi.dk/TermsAndCondition.html" target="_blank" rel="noopener noreferrer"> {t("terms & conditions")}</a>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </> :
                    <CommonEmptyView message='No license available' />
                }
            </div>
        </div>
    );
};

export default CarLicensePackage;