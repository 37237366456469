/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkAuthUser } from '../../../../../app/stores/others/authStore';
import useSettingsStore, { editProfile, getProfileDetails, updateTerms } from '../../../../../app/stores/others/settingsStore';
import { BUTTON_WIDTH, k_settings_paths } from '../../../../../app/utility/const';
import { iEditGray } from '../../../../../app/utility/imageImports';
import { Toastr, changePageTitle, isValidUrl } from '../../../../../app/utility/utilityFunctions';
import CommonButton from '../../../../../components/button/CommonButton';
import CommonButtonOutlined from '../../../../../components/button/CommonButtonOutlined';
import SquareImageUploader from '../../../../../components/imageUpload/SquareImageUploader';
import AddressAutoComplete from '../../../../../components/input/AddressAutoComplete';
import CommonInput from '../../../../../components/input/CommonInput';
import CommonTitle from '../../../../../components/title/CommonTitle';
import CompanyEditForm from './CompanyEditForm';


const EditCompanyProfile = () => {

    const navigateTo = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        changePageTitle(t('Settings | Edit Profile'));
    }, [])

    const {
        editCompanyProfile_form,
        profileDetails,
        setEditCompanyProfile_form,
        setCompanyProfileImage,
        companyProfileImage,
        termsData,
        setShowEditCompanyPolicyModal,
        setShowAboutCompanyModal,
        editTermsData,
        setEditTermsData
    } = useSettingsStore();

    const [updateFB, setUpdateFB] = useState("");
    const [updateTwitter, setUpdateTwitter] = useState("");
    const [updateLinkedin, setUpdateLinkedin] = useState("");

    const [doSearch, setDoSearch] = useState(false);
    const [addAddressLabel, setAddAddressLabel] = useState("");
    const [lat, setLat] = useState("");
    const [city, setCity] = useState("");

    useEffect(() => {
        fetchProfileDetails()
    }, [])

    const fetchProfileDetails = async () => {
        if (window.location.pathname === k_settings_paths.company_edit) {
            const success = await getProfileDetails();
            if (success) window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        setEditCompanyProfile_form({
            ...editCompanyProfile_form,
            id: profileDetails?.id ?? "",
            name: profileDetails?.name ?? "",
            cvr: profileDetails?.cvr ?? "",
            email: profileDetails?.email ?? "",
            phone: profileDetails?.phone ?? "",
            address: profileDetails?.address ?? "",
            website: profileDetails?.website ?? "",
            lng: profileDetails?.lng,
            about: profileDetails?.about ?? ""
        });
        setAddAddressLabel(profileDetails?.address);
        setLat(profileDetails?.lat);
        profileDetails?.social_media?.length > 0 &&
            profileDetails?.social_media?.map((item, index) => (
                <div key={index}>
                    {item?.domain.includes("facebook") && setUpdateFB(item?.link)}
                    {item?.domain.includes("twitter") && setUpdateTwitter(item?.link)}
                    {item?.domain.includes("linkedin") && setUpdateLinkedin(item?.link)}
                </div>
            ))
    }, [profileDetails])

    const handleChange = async (name, value) => {
        if (name === "address") {
            setAddAddressLabel(value);
        } else if (name === "lat") {
            setLat(value);
        } else if (name === "lng") {
            setEditCompanyProfile_form({ ...editCompanyProfile_form, lng: value });
        } else if (name === "city") {
            // setEditCompanyProfile_form({ ...editCompanyProfile_form, city: value });
            setCity(value);
        }
    };

    const validateUrl = (url, type) => {
        if (url && !isValidUrl(url)) {
            Toastr({ message: t(`Please Enter valid ${type} url`), type: "warning" });
            return false;
        }
        return true;
    };

    const createSocialMediaObject = (domain, link) => ({ domain, link: link ?? '' });

    const handleEditCompanyProfile = async () => {
        let body = {};
        let social_media = {};

        if (!validateUrl(updateFB, 'facebook') || !validateUrl(updateTwitter, 'twitter') || !validateUrl(updateLinkedin, 'linkedin')) {
            return;
        }

        social_media = JSON.stringify([
            createSocialMediaObject("facebook", updateFB),
            createSocialMediaObject("twitter", updateTwitter),
            createSocialMediaObject("linkedin", updateLinkedin)
        ]);

        body = {
            name: editCompanyProfile_form?.name ?? "",
            cvr: editCompanyProfile_form?.cvr ?? "",
            phone: editCompanyProfile_form?.phone ?? "",
            about: editCompanyProfile_form?.about ?? "",
            image: companyProfileImage ?? "",
            website: editCompanyProfile_form?.website ?? "",
            socials: social_media,
        };

        if (addAddressLabel !== null && addAddressLabel !== "") {
            if (lat && editCompanyProfile_form?.lng) {
                body = {
                    ...body,
                    address: addAddressLabel ?? "",
                    city: city ?? "",
                    lat: lat ?? "",
                    lng: editCompanyProfile_form?.lng,
                };
            } else {
                return Toastr({ message: t("Invalid Address!"), type: "warning" });
            }
        }

        if (body.website && !isValidUrl(body.website)) {
            return Toastr({ message: t("Please Enter valid url"), type: "warning" });
        }

        const success = await editProfile(body);
        let termsSuccess;

        if(editTermsData) {
            termsSuccess = await updateTerms();
        }

        if (success) {
            if(editTermsData && termsSuccess) {
                setEditTermsData("")
            }
            navigateTo(k_settings_paths.profile);
            checkAuthUser();
        }
    };

    return (
        <div>
            <div className='flex flex-col items-center md:flex-row md:justify-between'>

                <CommonTitle icon={true} link={k_settings_paths.profile} title={t("Edit Profile")} />

                <div className='flex items-center space-x-4 mt-s16 md:mt-0'>
                    <CommonButtonOutlined
                        onClick={() => {
                            navigateTo(k_settings_paths.profile);
                            setEditTermsData("")
                         }}
                        btnLabel={t("Cancel")}
                        colorType='danger'
                        width={BUTTON_WIDTH}
                    />

                    <CommonButton
                        btnLabel={t('Update')}
                        onClick={handleEditCompanyProfile}
                        width={BUTTON_WIDTH}
                    />
                </div>
            </div>

            <div className='grid grid-cols-12'>
                <div className='col-span-8'>
                    <div className='mt-s16'>
                        <SquareImageUploader
                            iImage={profileDetails?.image}
                            onChange={(e) => { setCompanyProfileImage(e) }}
                            clearImage={() => { setCompanyProfileImage(null) }}
                            rounded='!rounded-full'
                        />
                    </div>
                    <div className='mt-4 sub-title text-cGray800'>{t("Company Details")}</div>

                    <form>
                        <CompanyEditForm />
                        <AddressAutoComplete
                            label={t("Address")}
                            placeholder={t("Type Address..")} name={"address"}
                            address={addAddressLabel} latName={"lat"} lngName={"lng"} city={'city'}
                            changeValue={handleChange} doSearch={doSearch}
                            setDoSearch={setDoSearch}
                            icon={false}
                            isInvalid={addAddressLabel && (!lat || !editCompanyProfile_form?.lng)}
                        />

                        <div className='mt-5 sub-title text-cGray800'>{t("Social Links")}</div>
                        <CommonInput labelText={t('Facebook Profile Url')} value={updateFB} onChange={(e) => { setUpdateFB(e.target.value) }} hasInfoImage={true} tooltipMessage='https://facebook.com/abc'/>
                        <CommonInput labelText={t('Twitter Profile Url')} value={updateTwitter} onChange={(e) => { setUpdateTwitter(e.target.value) }} hasInfoImage={true} tooltipMessage='https://twitter.com/abc'/>
                        <CommonInput labelText={t('LinkedIn Profile Url')} onChange={(e) => { setUpdateLinkedin(e.target.value) }} value={updateLinkedin} hasInfoImage={true} tooltipMessage='https://linkedin.com/abc'/>
                        <div className='my-5'>
                            <div className='flex'>
                                <div className='mb-2 sub-title text-cGray800 mr-s4'>{t("About Company")}</div>
                                <img onClick={() => { setShowAboutCompanyModal(true) }}
                                    className='cursor-pointer'
                                    src={iEditGray} alt=""
                                />
                            </div>
                            <div className='text-fs14 text-cMainBlack font-fw400'>
                                {editCompanyProfile_form?.about ? <p className='max-w-full truncate whitespace-pre-wrap custom-text text-fs14 text-cMainBlack font-fw400'>
                                    {editCompanyProfile_form?.about === "null" || editCompanyProfile_form?.about === null ? 'NA' : editCompanyProfile_form?.about}
                                </p> : t('Information about the company is not updated yet.')}
                            </div>
                        </div>

                        <div>
                            <div className='flex'>
                                <div className='mb-2 sub-title text-cGray800 mr-s4'>{t("Company terms and conditions")}</div>
                                <img onClick={() => { setShowEditCompanyPolicyModal(true) }} className='cursor-pointer' src={iEditGray} alt="" />
                            </div>
                            {termsData || editTermsData ? <div className='max-w-full text-fs14 text-cMainBlack font-fw400 apply-all' dangerouslySetInnerHTML={{ __html: editTermsData ? editTermsData : termsData }} /> : 'Terms and conditions is not updated yet.'}
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
};

export default EditCompanyProfile;